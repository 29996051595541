import { adminurl, weburl, imgapi } from "@/utils/config";
import tools from "@/utils/public.js";
import store from "@/store";
const { toGet, toPost } = tools;
export default {
  // 查询文章列表
  async articleList(data) {
    data = {
      ...data,
      shop_id: store.getters.getShopId,
    };
    return await toPost(adminurl + "article/show", data);
  },
  // 新增文章
  async addArticle(data) {
    data = {
      ...data,
      shop_id: store.getters.getShopId,
    };
    return await toPost(adminurl + "article/add", data);
  },
  // 修改文章
  async updateArticle(data) {
    data = {
      ...data,
      shop_id: store.getters.getShopId,
    };
    return await toPost(adminurl + "article/update", data);
  },
  // 删除文章
  async delArticle(id) {
    return await toGet(adminurl + "article/delete/", id);
  },

  /****************************** */

  // 查询文章类别
  async articleCategory() {
    return await toGet(adminurl + "article/category/show/", store.getters.getShopId);
  },
  // 新增文章类别
  async addArticleCategory(data) {
    data = {
      ...data,
      shop_id: store.getters.getShopId,
    };
    return await toPost(adminurl + "article/category/add", data);
  },
  // 修改文章类别
  async updateArticleCategory(data) {
    data = {
      ...data,
      shop_id: store.getters.getShopId,
    };
    return await toPost(adminurl + "article/category/update", data);
  },
  // 删除文章类别
  async delArticleCategory(id) {
    return await toGet(adminurl + "article/category/delete/", id);
  },
};
