import { adminurl, weburl, imgapi } from "@/utils/config";
import tools from "@/utils/public.js";
import store from "@/store";
const { toGet, toPost } = tools;
export default {
  // 查询banner列表
  async bannerList() {
    let data = {
      types: "banner",
      shop_id: store.getters.getShopId,
    };
    return await toPost(adminurl + "system/index/show", data);
  },
  // 查询导航列表
  async naviList() {
    let data = {
      types: "navi_list",
      shop_id: store.getters.getShopId,
    };
    return await toPost(adminurl + "system/index/show", data);
  },
  // 查询热销列表
  async starList() {
    let data = {
      types: "star",
      shop_id: store.getters.getShopId,
    };
    return await toPost(adminurl + "system/index/show", data);
  },
  // 查询首页推荐商品列表
  async recommendList() {
    let data = {
      types: "recommend",
      shop_id: store.getters.getShopId,
    };
    return await toPost(adminurl + "system/index/show", data);
  },
  // 查询热搜词列表
  async hotSearchList() {
    return await toGet(adminurl + "system/search/show/", store.getters.getShopId);
  },
  // 查询首页弹窗
  async windowList() {
    let data = {
      types: "window",
      shop_id: store.getters.getShopId,
    };
    return await toPost(adminurl + "system/index/show", data);
  },
  // 查询个人中心广告
  async advList() {
    let data = {
      types: "adv",
      shop_id: store.getters.getShopId,
    };
    return await toPost(adminurl + "system/index/show", data);
  },
  // 查询分类页样式
  async cateType() {
    let data = {
      types: "cate_type",
      shop_id: store.getters.getShopId,
    };
    return await toPost(adminurl + "system/index/show", data);
  },
  // 添加一个分类配置
  async insertCateType() {
    let data = {
      type: "cate_type",
      value: 1,
      sort: 0,
      callback: "",
      img_url: "",
      shop_id: store.getters.getShopId,
    };
    return await toPost(adminurl + "system/index/add", data);
  },

  // 修改首页项目的状态设置
  async shopConfigStatus(data) {
    return await toPost(adminurl + "system/index/status", data);
  },
  // 删除首页配置
  async deleteShopConfig(id) {
    return await toGet(adminurl + "system/index/delete/", id);
  },
  // 添加首页配置
  async addShopConfig(data) {
    data = {
      ...data,
      shop_id: store.getters.getShopId,
    };
    return await toPost(adminurl + "system/index/add", data);
  },
  // 修改首页配置
  async updateShopConfig(data) {
    return await toPost(adminurl + "system/index/update", data);
  },
  // 添加热搜关键词
  async addShopSearch(data) {
    data = {
      ...data,
      shop_id: store.getters.getShopId,
    };
    return await toPost(adminurl + "system/search/add", data);
  },
  // 修改热搜关键词
  async updateShopSearch(data) {
    data = {
      ...data,
      shop_id: store.getters.getShopId,
    };
    return await toPost(adminurl + "system/search/update", data);
  },

  // 查询商城服务信息
  async vipServer() {
    return await toGet(adminurl + "system/show_vip_server/", store.getters.getShopId);
  },
  // 修改商城服务信息
  async updateVipServer(data) {
    return await toGet(adminurl + `system/update_vip_server/${store.getters.getShopId}/`, data);
  },

  // 展示协议
  async agreementConfig(id) {
    return await toGet(adminurl + "index/agreement_config/", id);
  },
  // 修改协议
  async updateAgreement(data) {
    return await toPost(adminurl + "index/update_agreement", data);
  },

  // 查询商家配置
  async showShopRatio() {
    return await toGet(adminurl + "system/show_shop_ratio/", store.getters.getShopId);
  },
  // 修改商家配置
  async modifyShopRatio(data) {
    data = {
      ...data,
      shop_id: store.getters.getShopId,
    };
    return await toPost(adminurl + "system/modify_shop_ratio", data);
  },

  // 商家登录
  async shopLogin(data) {
    return await toPost(weburl + "shop/login", data);
  },
  // 查询商家列表
  async shopList(data) {
    return await toPost(weburl + "shop/infos", data);
  },
  // 查询单个商家信息
  async shopInfo(id) {
    return await toGet(weburl + "shop/info/", id);
  },
  // 新增商家
  async addShopInfo(data) {
    return await toPost(weburl + "shop/add_info", data);
  },
  // 修改商家信息
  async modifyShopInfo(data) {
    data = {
      ...data,
      id: store.getters.getShopId,
    };
    return await toPost(weburl + "shop/modify_info", data);
  },

  // 查询商家支付相关配置
  async shopPayConfig() {
    return await toGet(adminurl + "pay_config/show/", store.getters.getShopId);
  },
  // 修改商家支付相关配置
  async modifyPayConfig(data) {
    return await toPost(adminurl + "pay_config/modify_config", data);
  },

  // 查询商家预约时间段配置
  async reservationList() {
    return await toGet(weburl + "shop/reservation/show/", store.getters.getShopId);
  },
  // 新增商家预约时间段配置
  async addReservation(data) {
    data = {
      ...data,
      shop_id: store.getters.getShopId,
    };
    return await toPost(weburl + "shop/reservation/add", data);
  },
  // 修改商家预约时间段配置
  async EditReservation(data) {
    return await toPost(weburl + "shop/reservation/modify", data);
  },
  // 删除商家预约时间段配置
  async DeleteReservation(id) {
    return await toGet(weburl + "shop/reservation/del/", id);
  },

  // 查询商家可预约日期情况
  async reservationDays(data) {
    data = {
      ...data,
      shop_id: store.getters.getShopId,
    };
    return await toPost(weburl + "user/show_time_slot", data);
  },

  // 展示商家休息日配置
  async showRest() {
    return await toGet(weburl + "shop/scheduling/show/", store.getters.getShopId);
  },
  // 新增休息日
  async addRest(data) {
    data = {
      ...data,
      shop_id: store.getters.getShopId,
    };
    return await toPost(weburl + "shop/scheduling/add", data);
  },
  // 删除休息日
  async delRest(id) {
    return await toGet(weburl + "shop/scheduling/del/", id);
  },
};
