import { adminurl, weburl, imgapi } from "@/utils/config";
import tools from "@/utils/public.js";
import store from "@/store";
const { toGet, toPost } = tools;
export default {
  // 查询服务者列表
  async staffList() {
    return await toGet(weburl + "shop/staff/show/", store.getters.getShopId);
  },
  // 新增服务者
  async addStaff(data) {
    data = {
      ...data,
      shop_id: store.getters.getShopId,
    };
    return await toPost(weburl + "shop/staff/add/", data);
  },
  // 修改服务者状态
  async updateStaff(data) {
    return await toPost(weburl + "shop/staff/modify", data);
  },
  // 删除服务者账号
  async deleteStaff(id) {
    return await toGet(weburl + "shop/staff/del/", id);
  },
  // 重置服务者账号密码
  async resetStaffPwd(id) {
    return await toGet(weburl + "user/reset_pwd/", id);
  },

  // 查询服务者服务单
  async staffServerOrder(data) {
    return await toPost(weburl + `shop/staff/staff_server_orders`, data);
  },
  // 扣除服务者提成
  async modifyStaffTc(data) {
    data = {
      ...data,
      shop_id: store.getters.getShopId,
    };
    return await toPost(weburl + `shop/staff/modify_staff_tc`, data);
  },
  // 查询服务者提成记录
  async staffTcLog(data) {
    return await toPost(weburl + `shop/staff/staff_tc_log`, data);
  },
};
